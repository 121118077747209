"use client";

import { useFormik } from "hooks";
import { useSignIn } from "hooks/session";
import Login from "./Login";
import Restore from "./Restore";

export default function HomePage() {
  const { loading, onSubmit, isSent } = useSignIn();
  const form = useFormik({ initialValues, onSubmit });

  return (
    <>
      <Login form={form} loading={loading} />
      <Restore form={form} disabled={isSent} />
    </>
  );
}

const initialValues = {
  email: "",
  password: "",
  view: "login",
};
